import type { EnvKV } from "./types";

export const values: EnvKV = {
  AmplitudeApiKey: "#{amplitude_api_key}",
  LaunchDarklyClientSideId: "#{launch_darkly_client_side_id}",
  SmokeballLaunchDarklyClientSideId: "#{launch_darkly_sb_client_side_id}",
  SmokeballClientId: "#{smokeball_client_id}",
  SmokeballCommunityUrl: "#{smokeball_community_url}",
  SmokeballSupportUrl: "#{smokeball_support_url}",
  GoogleCaptchaKey: "#{google_captcha_key}",
  Env: "#{smartforms_env}",
  Region: "#{smartforms_region}",
};
